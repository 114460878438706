import React, { useState, useRef } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { Button, Form } from "react-bootstrap"
import Layout from "../components/layout"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import SEO from "../components/seo"
import ReactGA from "react-ga"

const StyledWrapper = styled.div`
  width: 100%;
  padding: 5% 8%;
  overflow: scroll;

  @media (max-width: 600px) {
    padding-top: 8%;
    padding-bottom: 8%;
  }

  .form-control {
    height: 69px;
    background-color: #ececec;

    @media (max-width: 600px) {
      height: 50px;
      width: 100%;
    }
  }

  .form-label {
    font-family: "Nunito Sans";
    font-size: 21px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: 20px;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: normal;
    }
  }

  .submit-form {
    background-color: #5db9db;
    font-size: 21px;
    font-weight: bold;
    width: 100%;
    border: 0px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .anchor-class {
    color: black;
    text-decoration: underline;
  }

  .checkbox-link {
    display: inline-block;
    color: black;
    text-decoration: underline;
  }

  .fail-text {
    padding-top: 10px;
    padding-right: 80px;
    font-weight: bolder;
    color: #e43d5a;
  }

  .required-text {
    color: #e43d5a;
  }

  .captcha {
    padding: 20px 0 10px 0;
  }

  .policy-statement {
    padding-right: 20%;
  }

  .link {
    text-decoration: underline;
    color: black;
  }

  select {
    padding: 20px 0;
    display: flex;
    width: 100%;
    border: 1px solid #ced4da;
    background: #ececec;
    outline: none;
  }

  option {
    margin-left: 100px;
  }

  .toast-color {
    background: #5db9db;
  }

  .progressClass {
    background: white;
  }

  .toastBodyClass {
    color: black;
  }
`

export default function TeacherToolkit() {
  const { register, handleSubmit, watch, errors, setValue } = useForm({
    mode: "onChange",
  })
  const [error, setError] = useState("")
  const [feedbackMsg, setFeedbackMsg] = useState(null)
  const [selectReference, setSelectReference] = useState("")
  const enkiUrl = process.env.GATSBY_ENKI_URL
  const [disableBTN, setDisableBTN] = useState(false)

  const notifyToast = () => {
    return toast("Your details have been submitted", {
      className: "toast-color",
      progressClassName: "progressClass",
      bodyClassName: "toastBodyClass",
    })
  }

  const onSubmit = data => {
    fetch("https://formspree.io/f/xleoqapd", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
    fetch(`${enkiUrl}/aspire/teachers-submission`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
  .then(function (response) {
        if (response.status == 200) {
          setDisableBTN(true)
          notifyToast()
        } else {
          return response.json().then(data => setError(data.message))
        }
      })
      .catch(err => console.log(err))
    ReactGA.event({
      category: "Teacher Toolkit Request",
      action: "Submit",
    })
  }

  return (
    <Layout>
      <StyledWrapper>
        <SEO title="For Teachers" />
        <h1
          className="d-flex justify-content-center mb-0 py-3"
          style={{ color: "#5DB9DB" }}
        >
          Free resources for teachers
        </h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Label>
            First Name<span className="required-text">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            ref={register({ required: true })}
            name="first_name"
            placeholder="Enter your first name"
          />
          <Form.Label>
            Surname<span className="required-text">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            ref={register({ required: true })}
            name="surname"
            placeholder="Enter your last name"
          />
          <Form.Group controlId="formGridEmail">
            <Form.Label>
              Email<span className="required-text">*</span>
            </Form.Label>
            <Form.Control
              ref={register({ required: true })}
              name="email"
              placeholder="Enter your email address"
              type="email"
            />
          </Form.Group>

          <Form.Label>
            Position (e.g. Head of Sixth Form)
            <span className="required-text">*</span>
          </Form.Label>
          <Form.Control
            ref={register({ required: true })}
            name="position"
            type="text"
          />

          <Form.Label>
            School or Organisation Name<span className="required-text">*</span>
          </Form.Label>
          <Form.Control
            ref={register({ required: true })}
            name="school_org_name"
            placeholder=""
            type="text"
          />

          <Form.Label>School or Organisation Postcode</Form.Label>
          <Form.Control
            ref={register({ required: true })}
            name="school_org_postcode"
            placeholder=""
            type="text"
          />
          <Form.Label>
            Where did you hear about us?<span className="required-text">*</span>
          </Form.Label>
          <select
            name="hear_about_us"
            className="px-2"
            ref={register}
            onChange={e => setSelectReference(e.target.value)}
          >
            <option value=""></option>
            <option value="Letter from Aspire/upReach">
              Letter from Aspire/upReach
            </option>
            <option value="Teacher">Teacher</option>
            <option value="Career Advisor">Career Advisor</option>
            <option value="Parent/Guardian">Parent/Guardian</option>
            <option value="Student">Student</option>
            <option value="Aspire video">Aspire video</option>
            <option value="School assembly/presentation">
              School assembly/presentation
            </option>
            <option value="Tutor presentation">Tutor presentation</option>
            <option value="TES">TES</option>
            <option value="upReach website">upReach website</option>
            <option value="Social media (Facebook, Instagram etc)">
              Social media (Facebook, Instagram etc)
            </option>
            <option value="News outlet">News outlet</option>
            <option value="The Careers & Enterprise Company">
              The Careers & Enterprise Company
            </option>
            <option value="Other">
              Other (Please provide more detail in the box below)
            </option>
            {/* <option value="CEC">The Careers & Enterprise Company</option>
                    <option value="Letter from Aspire/upReach">Letter from Aspire/upReach</option>
                    <option value="News outlets">News outlets</option>
                    <option value="A parent">A parent</option>
                    <option value="A student">A student</option>
                    <option value="A teacher">A teacher</option>
                    <option value="TES">TES</option>
                    <option value="upReach">upReach website</option>
                    <option value="Other">Other (Please provide more detail in the box below)</option> */}
          </select>

          <div>
            {selectReference === "Other" && (
              <div>
                <Form.Label>If other, please give details</Form.Label>
                <Form.Control
                  name="hear_about_us_other"
                  placeholder=""
                  type="text"
                  ref={register({ required: selectReference === "Other" })}
                />
              </div>
            )}
          </div>

          <div className="form-check pt-3">
            <input
              className="form-check-input"
              type="checkbox"
              name="privacy_consent"
              ref={register}
            />
            <label
              className="form-check-label"
              style={{ paddingBottom: "10px" }}
            >
              I have read and understood the{" "}
              <AnchorLink to="/privacy-policy" className="anchor-class">
                Privacy Policy
              </AnchorLink>{" "}
              and consent to receiving emails from upReach. We will not pass on
              your details to any third parties.
            </label>
          </div>
          <Button
            className="submit-form mt-5 p-2"
            type="submit"
            disabled={disableBTN}
          >
            {disableBTN == false ? "SUBMIT" : "SUBMITTED"}
          </Button>
          {error != "" ? <p className="fail-text">{error}</p> : <></>}
          <ToastContainer />
        </Form>
      </StyledWrapper>
    </Layout>
  )
}
